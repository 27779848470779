import _ from "lodash";
import { withRouter } from 'react-router-dom';
import { useEffect } from "react";

export const isProduction = () => process.env.NODE_ENV === "production";

export function toggleItemInArray(array: Array<string>, item: any) {
  return _.xor(array, [item]);
}

export const ScrollToTop = withRouter(function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen((loc) => {
      if(loc.hash) {
        animateToId(loc.hash.slice(1));
      }
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [ history ]);

  return (null);
});

function animateToId(elementId, el:HTMLElement|null=null) {
  setTimeout(() => {
    if(!el) {
      el = document.getElementById(elementId);
      if(!el) return;
    }

    const offsetDiff = el.offsetTop - window.scrollY;
    window.scrollTo(0, window.scrollY + offsetDiff / 2);

    if(Math.abs(offsetDiff) > 3) {
      animateToId(null, el);
    }
  }, 50);
}
