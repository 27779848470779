import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite/no-important";
import { Link } from "react-router-dom";
import React from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";
import SectionHeading from "shared/components/SectionHeading";
import Subtext from "shared/components/Subtext";

const GET_FEEDLINKS = gql`
  query FeedLinks($first: Int) {
    feedLinks(first: $first, orderBy: order_ASC) {
      href
      title
      subtitle
    }
  }
`;

interface Props {
  loadAll?: boolean;
}

const FeedSection = ({ loadAll }: Props) => {
  const feedLinks = useQuery(GET_FEEDLINKS, {
    variables: { first: loadAll ? null : 3 },
  });
  return (
    <section>
      <PageBand innerStyle={styles.inner}>
        <div className={css(styles.narrowing)}>
          <SectionHeading text="What We Are " emText="Hearing:" />
          <Subtext>Here are a few resources that may be helpful</Subtext>
          {feedLinks.loading ? (
            <Subtext>Loading resources...</Subtext>
          ) : feedLinks.error ? (
            <Subtext>Error, please refresh to try again</Subtext>
          ) : (
            feedLinks.data.feedLinks.map((link, index) => {
              return (
                <div key={index} className={css(styles.feedLinkItem)}>
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css(styles.feedLink)}
                  >
                    {link.title}
                    <br />
                    <span className={css(styles.feedLinkSubtitle)}>
                      {link.subtitle}
                    </span>
                  </a>
                </div>
              );
            })
          )}
          {!loadAll && (
            <Link
              to="resources-to-help-reopen-u-s-buisnesses/"
              className={css(styles.moreLink)}
            >
              More resources...
            </Link>
          )}
        </div>
      </PageBand>
    </section>
  );
};

const styles = StyleSheet.create({
  inner: {
    paddingTop: 110,
    paddingBottom: 60,
    "@media (max-width: 768px)": {
      paddingTop: 30,
      paddingBottom: 20,
    },
  },
  narrowing: {
    width: "70%",
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  feedLinkItem: {
    marginBottom: 30,
  },
  feedLink: {
    fontSize: "160%",
    color: colors.accent,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
    "@media (max-width: 768px)": {
      fontSize: "120%",
    },
  },
  feedLinkSubtitle: {
    fontSize: "60%",
  },
  moreLink: {
    fontSize: "160%",
    color: colors.accent,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
    "@media (max-width: 768px)": {
      fontSize: "120%",
    },
  },
});

export default FeedSection;
