import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";

const QueryParams = ({ screenData, children, selfInstall }) => {
  return (
    <PageBand wrapperStyle={styles.queryParamWrapper} innerStyle={styles.inner}>
      <dl className={css(styles.queryParams)}>
        <div className={css(styles.param)}>
          <div className={css(styles.paramInner)}>
            <dt className={css(styles.queryParamName)}>Building Type</dt>
            <dd className={css(styles.queryParamValue)}>
              {screenData.data.leadDetails.buildingType.name}
            </dd>
          </div>
        </div>
        <div className={css(styles.param)}>
          <div className={css(styles.paramInner)}>
            <dt className={css(styles.queryParamName)}>Building Size</dt>
            <dd className={css(styles.queryParamValue, styles.noWrap)}>
              {screenData.data.leadDetails.buildingSize
                ? `${screenData.data.leadDetails.buildingSize.toLocaleString()} sq ft`
                : "Not Specified"}
            </dd>
          </div>
        </div>
        <div className={css(styles.param)}>
          <div className={css(styles.paramInner)}>
            <dt className={css(styles.queryParamName)}>Zip Code</dt>
            <dd className={css(styles.queryParamValue)}>
              {screenData.data.leadDetails.zipCode}
            </dd>
          </div>
        </div>
      </dl>
      {children}
    </PageBand>
  );
};

export default QueryParams;

const styles = StyleSheet.create({
  queryParamWrapper: {
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    paddingTop: 20,
    paddingBottom: 20,
    "@media (max-width: 768px)": {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  inner: {
    maxWidth: 768,
  },
  queryParams: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  param: {
    padding: "0 20px",
    margin: "8px 0",
    borderLeft: `1px solid ${colors.textOnAccent}`,
    marginLeft: -1,
    flex: 1,
    display: "flex",
    justifyContent: "space-around",
  },
  paramInner: {
    alignSelf: "center",
    textAlign: "center",
  },
  queryParamName: {
    color: colors.darkTextOnAccent,
    fontSize: "85%",
    marginBottom: 5,
  },
  queryParamValue: {
    fontWeight: 700,
    fontSize: "130%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
});
