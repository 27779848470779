import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";
import SectionHeading from "shared/components/SectionHeading";
import Subtext from "shared/components/Subtext";

import scrollDown from "static/scroll-down.svg";
import toolsToHelp from "static/ToolsToHelp.png";

const ToolsSection = () => {
  return (
    <section>
      <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
        <img
          src={scrollDown}
          className={css(styles.scrollDown)}
          alt="Scroll down to see more..."
        />
        <div className={css(styles.top)}>
          <SectionHeading
            text="We Have the "
            emText="Tools to Help."
            isOnAccentBg={true}
          />
          <Subtext>
            The resources &amp; information you need to reopen, at your
            fingertips.
          </Subtext>
        </div>
        <img
          src={toolsToHelp}
          className={css(styles.diagramImg)}
          alt="Tools to Help"
        />
        <p className={css(styles.legend)}>
          Healthy HVAC <span className={css(styles.legendSeparator)}>|</span>{" "}
          Operations <span className={css(styles.legendSeparator)}>|</span>{" "}
          Personal Health &amp; Privacy
          <br />
          Clean People, Clean Spaces{" "}
          <span className={css(styles.legendSeparator)}>|</span> Legal &amp;
          Regulatory
        </p>
      </PageBand>
    </section>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    background: `linear-gradient(to bottom, ${colors.accent} 0%, ${colors.accent} 60%, ${colors.pageBg} 60%)`,
    color: colors.textOnAccent,
  },
  inner: {
    borderBottom: `1px solid ${colors.accent}`,
    textAlign: "center",
    paddingTop: 110,
    paddingBottom: 60,
    "@media (max-width: 768px)": {
      paddingTop: 30,
      paddingBottom: 20,
    },
  },
  scrollDown: {
    width: 70,
    marginTop: 30,
    marginBottom: 40,
    marginLeft: "auto",
    marginRight: "auto",
    display: "none",
    "@media (max-width: 768px)": {
      display: "block",
    },
  },
  top: {
    width: "70%",
    margin: "0 auto",
  },
  diagramImg: {
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  legend: {
    marginTop: 40,
    marginBottom: 40,
    textAlign: "center",
    color: colors.text,
    fontSize: "130%",
    lineHeight: "1.3em",
    "@media (max-width: 768px)": {
      fontSize: "100%",
    },
  },
  legendSeparator: {
    color: colors.accent,
  },
});

export default ToolsSection;
