import { StyleSheet } from "aphrodite/no-important";
import React from "react";
import ClockLoader from 'react-spinners/ClockLoader';

import * as colors from 'shared/styles/colors';

import { PageBand } from 'shared/components/PageBand';

const Loading = () => {
  return (
    <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
      <ClockLoader size={150} color={colors.loadingSpinner} />
    </PageBand>
  );
};

export default Loading;

const styles = StyleSheet.create({
  wrapper: {
    width: '100vw',
    height: '100vh',
  },
  inner: {
    maxWidth: 150,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyItems: 'center',
  },
});
