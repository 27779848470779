import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";
import SectionHeading from "shared/components/SectionHeading";
import Subtext from "shared/components/Subtext";

const MastheadSection = () => {
  return (
    <section>
      <PageBand hasDoorWatermark={true}>
        <div className={css(styles.container)}>
          <SectionHeading
            text="Safe. Smart."
            emText="Open."
            emNewLine={true}
            isTopLevel={true}
          />
          <Subtext>
            COVID-19 interrupted everything. But now, as you work towards a
            return to the workplace, you can rethink what’s important and what’s
            possible. And you can be smart about it.
            <br />
            <br />
            Get all the partnerships, resources, information and supplies to
            reopen your business safely, and stay open.
          </Subtext>
          <a href="#get-started">
            <button className={css(styles.button)}>Get Started</button>
          </a>
        </div>
      </PageBand>
    </section>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 110,
    paddingBottom: 110,
    width: "70%",
    "@media (max-width: 768px)": {
      width: "90%",
      paddingTop: 30,
      paddingBottom: 65,
    },
  },
  button: {
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    paddingTop: 17,
    paddingRight: 34,
    paddingBottom: 17,
    paddingLeft: 34,
    borderRadius: 10,
    fontWeight: 700,
    fontSize: "150%",
    border: "none",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      fontSize: "100%",
      paddingTop: 12,
      paddingRight: 24,
      paddingBottom: 12,
      paddingLeft: 24,
    },
  },
});

export default MastheadSection;
