import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

import { Header, Footer } from 'shared/components/layout';
import Loading from 'shared/components/Loading';
import ErrorScreen from 'shared/components/Error';

import Resources from "modules/details/components/Resources";

const GET_RESOURCES = gql`
  query getResources($urlSlug: String!) {
    leadResourcePages(urlSlug: $urlSlug) {
      id
      order
      title
      source
      shortDesc
    }
  }
`;

const AllResources = () => {
  const { urlSlug } = useParams();
  const screenData = useQuery(GET_RESOURCES, {
    variables: { urlSlug },
  });
  useEffect(() => {
    document.title = `FREE Recommended Resources - ${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (<>
    <Header />
    { screenData.loading ? (
        <Loading />
      ) : screenData.error ? (
        <ErrorScreen />
      ) : (<Resources screenData={screenData} urlSlug={urlSlug} hasAll={true} />)}
    <Footer />
  </>);
}

export default AllResources;

