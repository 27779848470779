import { StyleSheet as Aphrodite } from "aphrodite/no-important";
import React from "react";

import projectMngt from "static/products/icon_projectmngt_higreen.svg";
import cameras from "static/products/icon_system_CAMERAS_hi green.svg";
import convey from "static/products/icon_system_convey_higreen.svg";
import coreStack from "static/products/icon_system_COREstackV_hi green.svg";
import compliance from "static/products/icon_system_CSRreport_higreen.svg";
import designPlanning from "static/products/icon_system_designplanning_higreen.svg";
import digitalSign from "static/products/icon_system_digitalsign_higreen.svg";
import doors from "static/products/icon_system_DOORS_hi green.svg";
import energy from "static/products/icon_system_ENERGY_hi green.svg";
import fireProtection from "static/products/icon_system_fireprotection_higreen.svg";
import hvac from "static/products/icon_system_HVAC_hi green.svg";
import iaq from "static/products/icon_system_IAQ_hi green.svg";
import lighting from "static/products/icon_system_LIGHTING_hi green.svg";
import peopleCount from "static/products/icon_system_PPL COUNT_hi green.svg";
import solar from "static/products/icon_system_solar_higreen.svg";
import utilityMeters from "static/products/icon_system_UTIL METERS_hi green.svg";
import water from "static/products/icon_system_WATER_hi green.svg";
import rfid from "static/products/RFID_higreen.svg";
import surgical from "static/products/icon_system_SURGICAL_higreen.svg";
import cleaning from "static/products/icon_system_CLEANING_higreen.svg";

import arrowDown from "static/products/arrowDown.svg";
import arrowUp from "static/products/arrowUp.svg";

export const productTypeIcons = {
  projectMngt,
  cameras,
  convey,
  coreStack,
  compliance,
  designPlanning,
  digitalSign,
  doors,
  energy,
  fireProtection,
  hvac,
  iaq,
  lighting,
  peopleCount,
  solar,
  utilityMeters,
  water,
  rfid,
  surgical,
  cleaning,
};

// Support '[open]' attribute selector for <details>
const { StyleSheet, css } = Aphrodite.extend([
  {
    selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
      if (
        selector.slice(0, 6) === "[open]" ||
        selector === "::-webkit-details-marker"
      ) {
        return generateSubtreeStyles(`${baseSelector}${selector}`);
      }
      return null;
    },
  },
]);

interface Props {
  iconName: String;
  title: String;
  rightText: String;
  children?: React.ReactNode;
}

const ProductTypeExpander = ({
  children,
  iconName,
  title,
  rightText,
}: Props) => {
  return (
    <details className={css(styles.productTypeDetails)}>
      <summary className={css(styles.productTypeSummary)}>
        <div className={css(styles.productTypeSummaryWrapper)}>
          {String(iconName) in productTypeIcons && (
            <img
              src={productTypeIcons[String(iconName)]}
              alt="Product Type Icon"
              className={css(styles.productTypeIcon)}
            />
          )}
          <span className={css(styles.productTypeName)}>{title}</span>
          <span className={css(styles.rightText)}>{rightText}</span>
          <img
            src={arrowDown}
            alt="Expand/Collapse"
            className={`arrow-up ${css(styles.productTypeArrow)}`}
          />
          <img
            src={arrowUp}
            alt="Expand/Collapse"
            className={`arrow-down ${css(
              styles.productTypeArrow,
              styles.hidden
            )}`}
          />
        </div>
      </summary>
      {children}
    </details>
  );
};

export default ProductTypeExpander;

const styles = StyleSheet.create({
  productTypeDetails: {
    "[open] img.arrow-up": {
      display: "none",
    },
    "[open] img.arrow-down": {
      display: "block",
    },
  },
  productTypeSummary: {
    display: "block",
    outline: "none",
    "::-webkit-details-marker": {
      display: "none",
    },
  },
  productTypeSummaryWrapper: {
    display: "flex",
    cursor: "pointer",
    padding: "20px 10px",
    fontSize: "115%",
    position: "relative",
  },
  productTypeName: {
    flex: 1,
    lineHeight: "32px",
  },
  rightText: {
    lineHeight: "32px",
    fontWeight: 700,
  },
  productTypeIcon: {
    maxWidth: 32,
    maxHeight: 32,
    alignSelf: "center",
    marginRight: 10,
  },
  productTypeArrow: {
    width: 14,
    height: 12,
    alignSelf: "center",
    marginLeft: 10,
  },
  hidden: {
    display: "none",
  },
});
