import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { css, StyleSheet } from "aphrodite/no-important";
import { Header, Footer } from 'shared/components/layout';
import React, { useState, useEffect } from "react";

import * as colors from 'shared/styles/colors';

import { PageBand } from 'shared/components/PageBand';
import SectionHeading from 'shared/components/SectionHeading';
import Subtext from 'shared/components/Subtext';

const SUBMIT_CONTACT = gql`
  mutation SubmitContact($email: String!, $note: String!) {
    submitContact(email: $email, note: $note) {
      ok
    }
  }
`;

const Contact = () => {
  const [ submitCompleted, setSubmitCompleted ] = useState(false);
  const [ submitContact, submitStatus ] = useMutation(SUBMIT_CONTACT, {
    onCompleted: (data) => {
      setSubmitCompleted(true);
    }
  });
  useEffect(() => {
    document.title = `Contact Us - ${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      <PageBand wrapperStyle={styles.wrapper}>
        <SectionHeading text="Contact us" />
        <Subtext style={styles.subtext}>We are here to help.  Please let us know if you have any questions, requests or suggestions:</Subtext>
        <form onSubmit={(event:any) => {
          event.preventDefault();
          submitContact({
            variables: {
              email: event.target.elements.email.value,
              note: event.target.elements.note.value,
            },
          });
          event.target.reset();
        }}>
          <label className={css(styles.field)}>
            <span className={css(styles.labelText)}>
              Enter your email address:
            </span>
            <input name="email" type="email" required className={css(styles.input)} placeholder="hello@yesweareopen.com" />
          </label>
          <label className={css(styles.field)}>
            <span className={css(styles.labelText)}>
              How can we help you?
            </span>
            <textarea name="note" required className={css(styles.textarea)} />
          </label>
          <input type="submit" className={css(styles.submit, styles.field)} value={ submitStatus.loading ? 'Loading...' : submitStatus.error ? 'Error! Please try again.' : 'Submit' } />
          {submitCompleted && (<Subtext style={styles.subtext}>Your message has been sent. We will respond as quickly as possible.</Subtext>)}
        </form>
      </PageBand>
      <Footer />
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    '@media (max-width: 768px)': {
      paddingTop: 40,
    },
  },
  subtext: {
    fontSize: '125%',
  },
  field: {
    display: 'block',
    marginBottom: 30,
  },
  labelText: {
    display: 'block',
    marginBottom: 10,
    fontSize: '110%',
  },
  input: {
    width: '100%',
    fontSize: '160%',
    padding: '4px 8px',
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: 10,
    border: 'none',
  },
  textarea: {
    width: '100%',
    height: 100,
    fontFamily: 'Rubik, sans-serif',
    fontSize: '160%',
    padding: '4px 8px',
    backgroundColor: '#ffffff',
    color: '#000000',
    borderRadius: 10,
    border: 'none',
  },
  submit: {
    width: '100%',
    padding: 7,
    color: colors.accent,
    backgroundColor: colors.textOnAccent,
    borderRadius: 10,
    border: 'none',
    fontSize: '150%',
    fontWeight: 700,
    cursor: 'pointer',
  },
});

export default Contact;


