import { StyleSheet as Aphrodite } from "aphrodite/no-important";
import React from "react";

import * as colors from "shared/styles/colors";

// Support child selectors for dynamic content
const { StyleSheet, css } = Aphrodite.extend([
  {
    selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
      if (selector.slice(0, 1) === "&") {
        return generateSubtreeStyles(`${baseSelector}${selector.slice(1)}`);
      }
      return null;
    },
  },
]);

interface Props {
  html: string;
}

const DynamicContent = ({ html }: Props) => {
  return (
    <div
      className={css(styles.content)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default DynamicContent;

const styles = StyleSheet.create({
  content: {
    lineHeight: "1.3em",
    "& a": {
      color: colors.accent,
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
    "& h1": {
      fontSize: "170%",
      lineHeight: "1.2em",
      marginBottom: 10,
    },
    "& h2": {
      fontSize: "150%",
      lineHeight: "1.2em",
      marginBottom: 10,
    },
    "& h3": {
      fontSize: "130%",
      lineHeight: "1.2em",
      marginBottom: 10,
    },
    "& p": {
      marginBottom: 10,
    },
    "& ul": {
      marginLeft: 30,
      marginBottom: 10,
    },
    "& ul li": {
      listStyle: "disc",
    },
    "& ol": {
      marginLeft: 30,
      marginBottom: 10,
    },
    "& ol li": {
      listStyle: "decimal",
    },
  },
});
