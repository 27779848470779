import { Header, Footer } from 'shared/components/layout';
import FeedSection from '../../components/FeedSection';
import React, { useEffect } from "react";

const Feed = () => {
  useEffect(() => {
    document.title = `Resources to Help Reopen US Businesses - ${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      <FeedSection loadAll={true} />
      <Footer />
    </>
  );
};

export default Feed;
