import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Style } from "shared/types";
interface Props {
  active?: Boolean;
  color: string;
  label?: string;
  onClick: Function;
  labelStyle?: Style | Style[];
}

export default function ({ active, color, onClick, label, labelStyle }: Props) {
  return (
    <button className={css(styles.button)} onClick={(event) => onClick(event)}>
      <div className={css(styles.ring)} style={{ borderColor: color }}>
        {active && (
          <span
            className={css(styles.orb)}
            style={{ backgroundColor: color }}
          ></span>
        )}
      </div>
      {label && (
        <span
          className={css(styles.label, labelStyle)}
          style={{ color: color }}
        >
          {label}
        </span>
      )}
    </button>
  );
}

const styles = StyleSheet.create({
  button: {
    cursor: "pointer",
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
  },
  ring: {
    width: 24,
    height: 24,
    padding: 3,
    borderRadius: 12,
    borderWidth: 2,
    borderStyle: "solid",
    display: "inline-block",
    verticalAlign: "bottom",
  },
  orb: {
    width: 14,
    height: 14,
    borderRadius: 7,
    display: "block",
  },
  label: {
    fontWeight: 700,
    fontSize: "150%",
    paddingLeft: 12,
    lineHeight: "26px",
  },
});
