import { StyleSheet } from "aphrodite/no-important";
import { Header, Footer } from 'shared/components/layout';
import React, { useEffect } from "react";

import { PageBand } from 'shared/components/PageBand';
import SectionHeading from 'shared/components/SectionHeading';
import Subtext from 'shared/components/Subtext';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = `Privacy Policy - ${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      <PageBand wrapperStyle={styles.wrapper}>
        <SectionHeading text="Privacy Statement." />
        <Subtext style={styles.subtext}>We all want to have confidence that each of us is safe, healthy and can get back to being productive as soon as possible. We share those goals and sensitivities, for ourselves, for those who interact with us (like you), and for our broader community where we live and work. With that in mind, we are exploring resources, tools and partners who share that respect for private information &#8212; who you and your business are, important company information, even government/national security secrets, if that applies. We are committed to respecting your privacy and building your trust. Because that’s what it will take to get going again.</Subtext>
        <Subtext style={styles.subtext}>We collect information to help us figure out what businesses need, where, and how urgently. We will not identify you or your business to third parties, or use your information to inundate you with offers from us or anyone else, without you expressing interest and authorizing us to do so. More specific terms may apply to various offerings from us, and each will state clearly how we will treat your personal and business information.</Subtext>
        <Subtext style={styles.subtext}>We hope that you and your business get open and get going again as soon as it is safe, and that we can travel that road forward together.</Subtext>
      </PageBand>
      <Footer />
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    '@media (max-width: 768px)': {
      paddingTop: 40,
    },
  },
  subtext: {
    fontSize: '125%',
  },
});

export default PrivacyPolicy;

