import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from 'shared/styles/colors';

import { PageBand } from 'shared/components/PageBand';
import { SmallButton } from 'shared/components/SmallButton';

import rightArrow from "static/products/rightArrow.svg";

interface Props {
  screenData: any,
  urlSlug: String,
  hasAll?: Boolean
}

const Resources = ({ screenData, urlSlug, hasAll }: Props) => {
  return (
    <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
      {hasAll && (
        <Link to={`/pak/${urlSlug}`}>
          <SmallButton style={styles.returnButton}>Return to Pak Details</SmallButton>
        </Link>
      )}
      <h2 className={css(styles.heading)}>FREE Recommended Resources</h2>
      <ul className={css(styles.resourceList)}>
        {screenData.data.leadResourcePages.map((resource, index) => { return (
          <li key={index} className={css(styles.resourceCard)}>
            <Link to={`/pak/${urlSlug}/resource/${resource.id}`} className={css(styles.resourceA)}>
              <h3 className={css(styles.resourceTitle)}>{resource.title}</h3>
              <p className={css(styles.resourceSource)}>{resource.source}</p>
              <p className={css(styles.resourceDesc)}>{resource.shortDesc}</p>
              <img src={rightArrow} alt="See More Details" className={css(styles.rightArrow)} />
            </Link>
          </li>
        )})}
      </ul>
      {!hasAll && (<Link to={`/pak/${urlSlug}/resource`} className={css(styles.button)}>View All</Link>)}
    </PageBand>
  );
};

export default Resources;

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 40,
    paddingBottom: 40,
    '@media (max-width: 768px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  inner: {
    maxWidth: 768,
  },
  returnButton: {
    marginBottom: 10,
  },
  heading: {
    fontWeight: 400,
    marginBottom: 24,
    lineHeight: '1.2em',
  },
  resourceList: {
  },
  resourceCard: {
  },
  resourceA: {
    padding: '18px 30px 18px 12px',
    borderRadius: 5,
    marginBottom: 10,
    backgroundColor: colors.detailsBg,
    position: 'relative',
    color: colors.text,
    display: 'block',
    ':hover': {
      backgroundColor: '#f9f9f9',
    }
  },
  rightArrow: {
    position: 'absolute',
    top: '50%',
    height: 25,
    marginTop: -12,
    right: 10,
  },
  resourceTitle: {
    marginBottom: 10,
  },
  resourceSource: {
    fontWeight: 700,
    fontSize: '90%',
    lineHeight: '1.2em',
    marginBottom: 5,
  },
  resourceDesc: {
    fontSize: '90%',
    lineHeight: '1.2em',
  },
  moreButton: {
    marginTop: 5,
    float: 'right',
  },
  button: {
    margin: '20px 15px 0 15px',
    border: `1px solid ${colors.accent}`,
    borderRadius: 10,
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    display: 'block',
    padding: 10,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '130%',
    ':hover': {
      color: colors.accent,
      backgroundColor: colors.textOnAccent,
    }
  },
});
