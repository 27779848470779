import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import { PageBand } from "shared/components/PageBand";
import SectionHeading from "shared/components/SectionHeading";
import Subtext from "shared/components/Subtext";

import openSign from "static/open-sign.svg";

const GoalSection = () => {
  return (
    <section>
      <PageBand wrapperStyle={styles.wrapper}>
        <img src={openSign} className={css(styles.watermark)} alt="Open Sign" />
        <div className={css(styles.narrowing)}>
          <SectionHeading text="Our " emText="Goal:" />
          <Subtext>
            We want to help ensure the safety &amp; security of your business as
            you reopen and stay open while we all find our way back to normal.
          </Subtext>
        </div>
      </PageBand>
    </section>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    overflow: "visible",
    paddingTop: 110,
    paddingBottom: 110,
    "@media (max-width: 768px)": {
      paddingTop: 30,
      paddingBottom: 65,
    },
  },
  narrowing: {
    width: "70%",
    zIndex: 2,
    position: "relative",
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  watermark: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: -100,
    width: 600,
    maxWidth: "100%",
    "@media (max-width: 768px)": {
      width: 300,
      top: 4,
      left: -150,
      transform: "rotate(20deg)",
    },
  },
});

export default GoalSection;
