import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from 'shared/styles/colors';
import { Style } from 'shared/types';

interface Props {
  children?: React.ReactNode,
  style?: Style | Style[],
  onClick?: Function,
}

export const SmallButton = ({ children, style, onClick }: Props) => {
  return (<button onClick={event => onClick && onClick(event)} className={css(styles.button, style)}>{children}</button>);
};

const styles = StyleSheet.create({
  button: {
    fontSize: '80%',
    padding: '5px 10px',
    border: `1px solid ${colors.accent}`,
    color: colors.accent,
    background: 'none',
    borderRadius: 12,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.accent,
      color: colors.textOnAccent,
    },
  },
});

