import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Link } from "react-router-dom";

import * as colors from 'shared/styles/colors';

import { PageBand } from 'shared/components/PageBand';
import SectionHeading from 'shared/components/SectionHeading';
import Subtext from 'shared/components/Subtext';

import lockWatermark from 'static/lock.svg';

const PrivacySection = () => {
  return (
    <section>
      <PageBand wrapperStyle={styles.wrapper}>
        <img src={lockWatermark} className={css(styles.watermark)} alt="Lock Watermark" />
        <div className={css(styles.narrowing)}>
          <SectionHeading text="Your Privacy" emText="Matters." emNewLine={true} />
          <Subtext>
            We take your personal privacy and the security of your company 
            information seriously. That’s why our platform is open source &amp; we 
            work only with a group of trusted partners.
          </Subtext>
          <Link to="/privacy" className={css(styles.moreLink)}>See our privacy policy...</Link>
        </div>
      </PageBand>
    </section>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    overflow: 'visible',
    paddingTop: 110,
    paddingBottom: 110,
    '@media (max-width: 768px)': {
      paddingTop: 30,
      paddingBottom: 65,
    },
  },
  narrowing: {
    width: '70%',
    zIndex: 2,
    position: 'relative',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  watermark: {
    position: 'absolute',
    zIndex: 1,
    top: -100,
    right: -100,
    width: 500,
    maxWidth: '100%',
    '@media (max-width: 768px)': {
      width: 250,
      top: 40,
    },
  },
  moreLink: {
    fontSize: '160%',
    color: colors.accent,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
    '@media (max-width: 768px)': {
      fontSize: '120%',
    },
  },
});

export default PrivacySection;

