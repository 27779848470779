import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";
import SectionHeading from "shared/components/SectionHeading";
import Subtext from "shared/components/Subtext";

const GET_BUILDINGTYPES = gql`
  {
    buildingTypes {
      id
      name
    }
  }
`;

const SUBMIT_QUERYLEAD = gql`
  mutation CreateQueryLead(
    $email: String!
    $buildingTypeId: String!
    $buildingSize: Int!
    $zipCode: String!
  ) {
    createQueryLead(
      email: $email
      buildingTypeId: $buildingTypeId
      buildingSize: $buildingSize
      zipCode: $zipCode
    ) {
      urlSlug
    }
  }
`;

const QuerySection = () => {
  const history = useHistory();
  const buildingTypes = useQuery(GET_BUILDINGTYPES);
  const [createQueryLead, queryLeadStatus] = useMutation(SUBMIT_QUERYLEAD, {
    onCompleted: (data) => {
      history.push(`/pak/${data.createQueryLead.urlSlug}`);
    },
  });
  return (
    <section id="get-started">
      <PageBand wrapperStyle={styles.wrapper}>
        <SectionHeading
          text="Help Us "
          emText="Help You."
          isOnAccentBg={true}
        />
        <Subtext>
          To get started, just answer a few questions about your situation:
        </Subtext>
        <form
          className={css(styles.form)}
          onSubmit={(event: any) => {
            event.preventDefault();
            const inputs = event.target.elements;
            let buildingSize = parseInt(inputs.buildingSize.value, 10);
            if (isNaN(buildingSize) || buildingSize < 0) {
              buildingSize = 0;
            }
            createQueryLead({
              variables: {
                email: inputs.email.value,
                buildingTypeId: inputs.buildingTypeId.value,
                buildingSize,
                zipCode: inputs.zipCode.value || "00000",
              },
            });
          }}
        >
          <label className={css(styles.field, styles.fullWidthField)}>
            <span className={css(styles.labelText)}>
              Enter your email address so we can send you a copy of your
              results:
            </span>
            <input
              name="email"
              type="email"
              required
              className={css(styles.input)}
              placeholder="hello@yesweareopen.com"
            />
          </label>
          <label className={css(styles.field)}>
            <span className={css(styles.labelText)}>
              What type of building do you have?
            </span>
            <span className={css(styles.notSureLinkContainer)}>
              <a
                target="_blank"
                href="https://verdicity.com/blog-1"
                className={css(styles.notSureLink)}
                rel="noopener noreferrer"
              >
                I'm not sure
              </a>
            </span>
            <select
              name="buildingTypeId"
              className={css(styles.select)}
              required
            >
              {buildingTypes.loading ? (
                <option>Loading...</option>
              ) : buildingTypes.error ? (
                <option>Error, please refresh to try again</option>
              ) : (
                [{ id: "", name: "Select One..." }]
                  .concat(buildingTypes.data.buildingTypes)
                  .map((buildingType) => {
                    return (
                      <option key={buildingType.id} value={buildingType.id}>
                        {buildingType.name}
                      </option>
                    );
                  })
              )}
            </select>
          </label>
          <label className={css(styles.field)}>
            <span className={css(styles.labelText)}>
              How big is your building? (Square Feet)
            </span>
            <input
              name="buildingSize"
              type="number"
              step="any"
              min="0"
              className={css(styles.input)}
              required
              placeholder="ft²"
            />
          </label>
          <label className={css(styles.field)}>
            <span className={css(styles.labelText)}>
              In which zip code is your building?
            </span>
            <input
              name="zipCode"
              type="text"
              className={css(styles.input)}
              placeholder="02134"
              required
              pattern="^\d{5}$"
            />
          </label>
          <input
            type="submit"
            className={css(styles.submit)}
            value={
              queryLeadStatus.loading
                ? "Loading..."
                : queryLeadStatus.error
                ? "Error! Please try again."
                : "Next"
            }
          />
        </form>
      </PageBand>
    </section>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    textAlign: "center",
    paddingTop: 110,
    paddingBottom: 60,
    "@media (max-width: 768px)": {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  form: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-between",
  },
  field: {
    flex: "1 1 10%",
    padding: "33px 10px",
    margin: 10,
    borderRadius: 10,
    backgroundColor: colors.pageBg,
    color: colors.text,
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      width: "100%",
      flex: "unset",
    },
  },
  fullWidthField: {
    width: "100%",
    flex: "unset",
  },
  labelText: {
    fontSize: "110%",
    lineHeight: "1.2em",
    padding: "0 18px",
    textAlign: "left",
    display: "block",
  },
  input: {
    width: "calc(100% - 36px)",
    margin: "0 18px",
    fontSize: "160%",
    padding: "4px 8px",
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: 10,
    border: "none",
    "@media (max-width: 480px)": {
      fontSize: "100%",
      padding: "12px 8px",
    },
    "@media (max-width: 380px)": {
      fontSize: "80%",
      padding: "14px 8px",
    },
  },
  select: {
    width: "calc(100% - 36px)",
    margin: "0 18px",
    fontSize: "160%",
    padding: "4px 8px",
    "@media (max-width: 480px)": {
      fontSize: "100%",
      padding: "12px 8px",
    },
    "@media (max-width: 380px)": {
      fontSize: "80%",
      padding: "14px 8px",
    },
  },
  submit: {
    width: "100%",
    flex: "unset",
    margin: 10,
    padding: 7,
    color: colors.accent,
    backgroundColor: colors.textOnAccent,
    borderRadius: 10,
    border: "none",
    fontSize: "150%",
    fontWeight: 700,
    cursor: "pointer",
  },
  notSureLinkContainer: {
    marginTop: 5,
    marginBottom: 15,
    marginRight: 18,
    textAlign: "right",
  },
  notSureLink: {
    textDecoration: "underline",
    color: colors.darkTextOnAccent,
  },
});

export default QuerySection;
