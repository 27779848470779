import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useEffect } from "react";
import LaunchIcon from '@material-ui/icons/Launch';

import * as colors from 'shared/styles/colors';

import { Header, Footer } from 'shared/components/layout';
import { PageBand } from 'shared/components/PageBand';
import { SmallButton } from 'shared/components/SmallButton';
import DynamicContent from 'shared/components/DynamicContent';
import Loading from 'shared/components/Loading';
import ErrorScreen from 'shared/components/Error';


const GET_RESOURCE = gql`
  query getResource($resourceId: ID!) {
    resourcePage(where: { id: $resourceId }) {
      id
      title
      source
      sourceHref
      description
    }
  }
`;

const Resource = () => {
  const { urlSlug, resourceId } = useParams();
  const screenData = useQuery(GET_RESOURCE, {
    variables: { resourceId }
  });
  useEffect(() => {
    document.title = `${!(screenData.loading || screenData.error) && (screenData.data.resourcePage.title + ' - ')}${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (<>
    <Header />
    { screenData.loading ? (
        <Loading />
      ) : screenData.error ? (
        <ErrorScreen />
      ) : (
      <>
        <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
          <Link to={`/pak/${urlSlug}`}>
            <SmallButton style={styles.returnButton}>Return to Pak Details</SmallButton>
          </Link>
          <h1 className={css(styles.heading)}>{screenData.data.resourcePage.title}</h1>
          <p className={css(styles.topText)}>
            {screenData.data.resourcePage.sourceHref ? (
              <a target="_blank" rel="noopener noreferrer"
                 href={screenData.data.resourcePage.sourceHref}
                 className={css(styles.sourceLink)}>
                {screenData.data.resourcePage.source}
                <LaunchIcon style={{ fontSize: 14, marginLeft: 4 }} />
              </a>
            ) : screenData.data.resourcePage.source}
          </p>
        </PageBand>
        <PageBand wrapperStyle={styles.contentWrapper} innerStyle={styles.inner}>
          <DynamicContent html={screenData.data.resourcePage.description} />
        </PageBand>
      </>)
    }
    <Footer />
  </>);
}

export default Resource;

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 40,
    paddingBottom: 40,
    '@media (max-width: 768px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  contentWrapper: {
    backgroundColor: colors.detailsBg,
    paddingTop: 40,
    paddingBottom: 40,
    '@media (max-width: 768px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  inner: {
    maxWidth: 768,
  },
  returnButton: {
    marginBottom: 10,
  },
  heading: {
    fontSize: '170%',
    color: colors.accent,
    marginBottom: 10,
  },
  topText: {
  },
  sourceLink: {
    color: colors.text,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    }
  },
});

