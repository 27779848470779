import { Header, Footer } from 'shared/components/layout';
import FeedSection from '../../components/FeedSection';
import MastheadSection from '../../components/MastheadSection';
import ToolsSection from '../../components/ToolsSection';
import GoalSection from '../../components/GoalSection';
import PrivacySection from '../../components/PrivacySection';
import QuerySection from '../../components/QuerySection';
import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      <MastheadSection />
      <ToolsSection />
      <FeedSection />
      <GoalSection />
      <PrivacySection />
      <QuerySection />
      <Footer />
    </>
  );
};

export default Home;
