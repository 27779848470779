import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useState } from "react";

import * as colors from "shared/styles/colors";

import { totalEstimate } from "modules/details/estimateHelpers";
import ContactButton from "modules/details/components/ContactButton";
import { PageBand } from "shared/components/PageBand";

const Actions = ({ screenData, urlSlug, selfInstall }) => {
  const [copyStatus, setCopyStatus] = useState("");

  return (
    <PageBand wrapperStyle={styles.actionWrapper} innerStyle={styles.inner}>
      <p className={css(styles.totalCost)}>
        Total cost: $
        {totalEstimate(screenData.data, selfInstall).toLocaleString()}
      </p>
      <p className={css(styles.costDesc)}>
        The cost above is based on the details provided, and can always be
        tailored to your specific needs.
        <br />
        <br />
        Please Contact Us if you have questions or if you are{" "}
        <strong>ready to buy</strong>.
        <br />
        <br />
        Create a new search to calculate based on new building details, or copy
        the link to share your estimate.
      </p>
      <ContactButton urlSlug={urlSlug} />
      <Link to="/#get-started" className={css(styles.button)}>
        Create New Search
      </Link>
      <button
        className={css(styles.copyLink)}
        onClick={() => {
          navigator.clipboard &&
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_PUBLIC_URL}/pak/${urlSlug}`
            );
          setCopyStatus("Link Copied");
          setTimeout(() => setCopyStatus(""), 1500);
        }}
      >
        {copyStatus ? copyStatus : "Copy Link"}
      </button>
    </PageBand>
  );
};

export default Actions;

const styles = StyleSheet.create({
  actionWrapper: {
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    paddingTop: 25,
    paddingBottom: 25,
    "@media (max-width: 768px)": {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  inner: {
    maxWidth: 768,
  },
  totalCost: {
    fontWeight: 700,
    fontSize: "200%",
    textAlign: "center",
    marginBottom: 16,
  },
  costDesc: {
    color: colors.darkTextOnAccent,
    textAlign: "center",
    marginBottom: 15,
    lineHeight: "1.2em",
  },
  button: {
    border: `1px solid ${colors.accent}`,
    borderRadius: 10,
    color: colors.textOnAccent,
    display: "block",
    padding: 10,
    margin: "8px 15px 0 15px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "130%",
    ":hover": {
      color: colors.accent,
      backgroundColor: colors.textOnAccent,
    },
  },
  copyLink: {
    display: "block",
    width: "100%",
    color: colors.text,
    cursor: "pointer",
    background: "none",
    textAlign: "center",
    fontSize: "130%",
    marginTop: 16,
    ":hover": {
      textDecoration: "underline",
    },
  },
});
