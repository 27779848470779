import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useEffect, useState } from "react";
import LaunchIcon from "@material-ui/icons/Launch";

import * as colors from "shared/styles/colors";

import { Header, Footer } from "shared/components/layout";
import { PageBand } from "shared/components/PageBand";
import { SmallButton } from "shared/components/SmallButton";
import DynamicContent from "shared/components/DynamicContent";
import Loading from "shared/components/Loading";
import ErrorScreen from "shared/components/Error";
import Radio from "shared/components/Radio";

import {
  productQuantity,
  productCostEstimate,
  productUnitPrice,
  prodConfigValue,
} from "modules/details/estimateHelpers";
import ContactButton from "modules/details/components/ContactButton";

const GET_PRODUCT = gql`
  query getProduct($urlSlug: String!, $productId: ID!) {
    productOffering(where: { id: $productId }) {
      id
      name
      companyName
      companyHref
      description
      featuredImageUrl
      productType {
        id
        name
        order
        iconName
      }
      unitPriceCents
      installationPriceCents
      provisionPriceCents
      quantityMultiplier
      quantityByBuildingSize
      priceIsAnnual
      priceIsCustom
    }
    leadDetails(urlSlug: $urlSlug) {
      buildingSize
    }
    queryProducts(
      where: {
        queryLeadSlug: $urlSlug
        OR: [{ productId: $productId }, { productId: null }]
      }
    ) {
      productId
      selfInstall
    }
  }
`;

const SET_CONFIG = gql`
  mutation setConfig(
    $urlSlug: String!
    $productId: String!
    $selfInstall: Boolean!
  ) {
    setProductConfig(
      queryLeadSlug: $urlSlug
      productId: $productId
      config: { selfInstall: $selfInstall }
    ) {
      ok
    }
  }
`;

const Product = () => {
  const { urlSlug, productId } = useParams();
  const screenData = useQuery(GET_PRODUCT, {
    variables: { urlSlug, productId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setSelfInstall(
        prodConfigValue("selfInstall", productId, data.queryProducts, true)
      );
    },
  });
  const [setConfig] = useMutation(SET_CONFIG);

  const [selfInstall, setSelfInstall] = useState<Boolean | undefined>(
    undefined
  );
  useEffect(() => {
    document.title = `${
      !(screenData.loading || screenData.error)
        ? screenData.data.productOffering.name + " - "
        : ""
    }${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      {screenData.loading ? (
        <Loading />
      ) : screenData.error ? (
        <ErrorScreen />
      ) : (
        <>
          <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
            <Link to={`/pak/${urlSlug}`}>
              <SmallButton style={styles.returnButton}>
                Return to Pak Details
              </SmallButton>
            </Link>
            <h1 className={css(styles.heading)}>
              {screenData.data.productOffering.name}
            </h1>
            <p className={css(styles.topText)}>
              {screenData.data.productOffering.productType.name}
            </p>
            {screenData.data.productOffering.installationPriceCents && (
              <div className={css(styles.topField)}>
                <p className={css(styles.topFieldText)}>
                  Are you planning on installing yourself?
                </p>
                <Radio
                  color={colors.text}
                  labelStyle={styles.leftRadio}
                  label="Yes"
                  active={selfInstall !== undefined ? selfInstall : false}
                  onClick={() => {
                    setConfig({
                      variables: { urlSlug, productId, selfInstall: true },
                    });
                    setSelfInstall(true);
                  }}
                />
                <Radio
                  color={colors.text}
                  label="No, I want help."
                  active={selfInstall !== undefined ? !selfInstall : false}
                  onClick={() => {
                    setConfig({
                      variables: { urlSlug, productId, selfInstall: false },
                    });
                    setSelfInstall(false);
                  }}
                />
              </div>
            )}
          </PageBand>
          <PageBand
            wrapperStyle={styles.contentWrapper}
            innerStyle={styles.inner}
          >
            <dl className={css(styles.queryParams)}>
              <div className={css(styles.param)}>
                <div className={css(styles.paramInner)}>
                  <dt className={css(styles.queryParamName)}>Quantity</dt>
                  <dd className={css(styles.queryParamValue)}>
                    {productQuantity(
                      screenData.data.productOffering,
                      screenData.data.leadDetails
                    ).toLocaleString()}
                  </dd>
                </div>
              </div>
              <div className={css(styles.param)}>
                <div className={css(styles.paramInner)}>
                  <dt className={css(styles.queryParamName)}>Price each</dt>
                  <dd className={css(styles.queryParamValue, styles.noWrap)}>
                    $
                    {productUnitPrice(
                      screenData.data.productOffering,
                      selfInstall
                    ) < 100000
                      ? (
                          productUnitPrice(
                            screenData.data.productOffering,
                            selfInstall
                          ) / 100
                        ).toFixed(2)
                      : Math.round(
                          productUnitPrice(
                            screenData.data.productOffering,
                            selfInstall
                          ) / 100
                        ).toLocaleString()}
                  </dd>
                </div>
              </div>
              <div className={css(styles.param)}>
                <div className={css(styles.paramInner)}>
                  <dt className={css(styles.queryParamName)}>Total price</dt>
                  <dd className={css(styles.queryParamValue)}>
                    $
                    {productCostEstimate(
                      screenData.data.productOffering,
                      screenData.data.leadDetails,
                      selfInstall
                    ).toLocaleString()}
                    {screenData.data.productOffering.priceIsAnnual && " / year"}
                    {screenData.data.productOffering.priceIsCustom && "*"}
                  </dd>
                </div>
              </div>
            </dl>
            {screenData.data.productOffering.featuredImageUrl && (
              <img
                src={screenData.data.productOffering.featuredImageUrl}
                alt={screenData.data.productOffering.name}
                className={css(styles.featuredImage)}
              />
            )}
            <p className={css(styles.companyName)}>
              {screenData.data.productOffering.companyHref ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={screenData.data.productOffering.companyHref}
                  className={css(styles.companyLink)}
                >
                  {screenData.data.productOffering.companyName}
                  <LaunchIcon style={{ fontSize: 14, marginLeft: 4 }} />
                </a>
              ) : (
                screenData.data.productOffering.companyName
              )}
            </p>
            <DynamicContent
              html={screenData.data.productOffering.description}
            />
            <ContactButton urlSlug={urlSlug} style={styles.contactButton} />
            {screenData.data.productOffering.priceIsCustom && (
              <p className={css(styles.footNote)}>
                *Total price is only an estimate. Please contact us for custom
                pricing information.
              </p>
            )}
          </PageBand>
        </>
      )}
      <Footer />
    </>
  );
};

export default Product;

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 40,
    paddingBottom: 40,
    "@media (max-width: 768px)": {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  contentWrapper: {
    backgroundColor: colors.detailsBg,
    paddingTop: 20,
    paddingBottom: 40,
    "@media (max-width: 768px)": {
      paddingTop: 20,
      paddingBottom: 40,
    },
  },
  inner: {
    maxWidth: 768,
  },
  returnButton: {
    marginBottom: 10,
  },
  heading: {
    fontSize: "170%",
    color: colors.accent,
    marginBottom: 10,
  },
  topText: {},
  topField: {},
  topFieldText: {
    fontSize: "80%",
    margin: "20px 0",
  },
  leftRadio: {
    paddingRight: 40,
  },
  queryParams: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    marginBottom: 35,
  },
  param: {
    padding: "0 20px",
    margin: "8px 0",
    borderLeft: `1px solid ${colors.text}`,
    marginLeft: -1,
    flex: 1,
    display: "flex",
    justifyContent: "space-around",
  },
  paramInner: {
    alignSelf: "center",
    textAlign: "center",
  },
  queryParamName: {
    color: colors.darkTextOnAccent,
    fontSize: "85%",
    marginBottom: 5,
  },
  queryParamValue: {
    fontWeight: 700,
    fontSize: "130%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  featuredImage: {
    maxWidth: "100%",
    margin: "20px auto",
    display: "block",
  },
  companyName: {
    fontWeight: 700,
    marginBottom: 10,
  },
  companyLink: {
    color: colors.text,
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
  contactButton: {
    marginTop: 40,
  },
  footNote: {
    marginTop: 20,
    fontSize: "80%",
  },
});
