import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useState } from "react";

import Subtext from "shared/components/Subtext";

import * as colors from "shared/styles/colors";
import { Style } from "shared/types";

interface Props {
  style?: Style | Style[];
  urlSlug: String;
}

const SUBMIT_CONTACT = gql`
  mutation SubmitQueryLeadContact($urlSlug: String!) {
    submitQueryLeadContact(urlSlug: $urlSlug) {
      ok
    }
  }
`;

const ContactButton = ({ urlSlug, style }: Props) => {
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitContact, submitStatus] = useMutation(SUBMIT_CONTACT, {
    onCompleted: (data) => {
      setSubmitCompleted(true);
    },
  });
  return (
    <>
      <button
        onClick={(event) => {
          event.preventDefault();
          submitContact({ variables: { urlSlug } });
        }}
        className={css(styles.button, style)}
      >
        {submitStatus.loading
          ? "Submitting..."
          : submitStatus.error
          ? "Error! Please try again."
          : "Contact Us"}
      </button>
      {submitCompleted && (
        <Subtext style={styles.subtext}>
          Your request has been sent. We will respond by email as quickly as
          possible.
        </Subtext>
      )}
    </>
  );
};

export default ContactButton;

const styles = StyleSheet.create({
  button: {
    border: `1px solid ${colors.textOnAccent}`,
    borderRadius: 10,
    backgroundColor: colors.accent,
    color: colors.textOnAccent,
    display: "block",
    cursor: "pointer",
    width: "calc(100% - 30px)",
    padding: 10,
    margin: "20px 15px 0 15px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "130%",
    ":hover": {
      color: colors.accent,
      backgroundColor: colors.textOnAccent,
    },
  },
  subtext: {
    fontSize: "100%",
    marginTop: 20,
    textAlign: "center",
  },
});
