import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useEffect, useState } from "react";

import * as colors from "shared/styles/colors";

import { Header, Footer } from "shared/components/layout";
import { PageBand } from "shared/components/PageBand";
import Loading from "shared/components/Loading";
import ErrorScreen from "shared/components/Error";
import Radio from "shared/components/Radio";

import QueryParams from "modules/details/components/QueryParams";
import Products from "modules/details/components/Products";
import Resources from "modules/details/components/Resources";
import Actions from "modules/details/components/Actions";

const GET_PRODUCTOFFERINGS = gql`
  query getProducts($urlSlug: String!) {
    leadProductOfferings(urlSlug: $urlSlug) {
      id
      name
      companyName
      shortDesc
      productType {
        id
        name
        order
        iconName
      }
      unitPriceCents
      installationPriceCents
      provisionPriceCents
      quantityMultiplier
      quantityByBuildingSize
      priceIsAnnual
      priceIsCustom
    }
    leadDetails(urlSlug: $urlSlug) {
      buildingSize
      buildingType {
        id
        name
      }
      zipCode
    }
    leadResourcePages(first: 5, urlSlug: $urlSlug) {
      id
      order
      title
      source
      shortDesc
    }
    queryProducts(where: { queryLeadSlug: $urlSlug }) {
      productId
      selfInstall
    }
  }
`;

const SET_CONFIG = gql`
  mutation setConfig($urlSlug: String!, $selfInstall: Boolean!) {
    setProductConfig(
      queryLeadSlug: $urlSlug
      config: { selfInstall: $selfInstall }
    ) {
      ok
    }
  }
`;

function queryConfigValue(key, queryProducts) {
  const queryConfig = queryProducts.find((x) => x.productId === null);
  if (!queryConfig) return undefined;
  return queryProducts[0][key];
}

function isConfigValueComplex(key, queryProducts, defaultVal) {
  if (queryProducts.length === 0) return undefined;
  const firstVal = queryProducts[0][key];
  let hasQueryLevel = false;
  for (let prod of queryProducts) {
    if (prod.productId === null) hasQueryLevel = true;
    if (prod[key] !== firstVal) return true;
  }
  if (!hasQueryLevel && firstVal !== defaultVal) return true;
  return false;
}

const Details = () => {
  const { urlSlug } = useParams();
  const screenData = useQuery(GET_PRODUCTOFFERINGS, {
    variables: { urlSlug },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const selfInstallValue = queryConfigValue(
        "selfInstall",
        data.queryProducts
      );
      if (selfInstallValue !== undefined) setSelfInstall(selfInstallValue);
      setIsComplex(
        isConfigValueComplex("selfInstall", data.queryProducts, true) || false
      );
    },
  });
  const [setConfig] = useMutation(SET_CONFIG);
  const [selfInstall, setSelfInstall] = useState(true);
  const [showUndoCustom, setShowUndoCustom] = useState(false);
  const [desiredSelfInstall, setDesiredSelfInstall] = useState(false);
  const [isComplex, setIsComplex] = useState(true);
  useEffect(() => {
    document.title = `My Reopening Pak - ${process.env.REACT_APP_SITE_TITLE}`;
  });
  return (
    <>
      <Header />
      {screenData.loading ? (
        <Loading />
      ) : screenData.error ? (
        <ErrorScreen />
      ) : (
        <>
          <PageBand wrapperStyle={styles.topWrapper} innerStyle={styles.inner}>
            <h1 className={css(styles.heading)}>My Reopening Pak</h1>
            <p className={css(styles.topText)}>
              Your Reopening Pak estimates costs to return to the workplace safe
              and smart. Buying from Verdicity ensures all products and services
              are certified to work together in a unified solution, now and into
              the future, as your goals for your smart building(s) expand.
              Please Contact Us with any questions or to quickly buy your
              solution.
            </p>
          </PageBand>
          <QueryParams
            selfInstall={isComplex ? undefined : selfInstall}
            {...{ screenData }}
          >
            <div className={css(styles.topField)}>
              <p className={css(styles.topFieldText)}>
                Are you planning on installing yourself?
              </p>
              {showUndoCustom ? (
                <div className={css(styles.undoCustom)}>
                  <p className={css(styles.undoCustomText)}>
                    Selecting "Yes" or "No" will undo all of your custom
                    changes.
                  </p>
                  <p className={css(styles.undoCustomText)}>
                    Would you like to proceed?
                  </p>
                  <Radio
                    labelStyle={styles.leftRadio}
                    color={colors.accent}
                    label="Proceed"
                    active={false}
                    onClick={() => {
                      setConfig({
                        variables: { urlSlug, selfInstall: desiredSelfInstall },
                      });
                      setSelfInstall(desiredSelfInstall);
                      setIsComplex(false);
                      setShowUndoCustom(false);
                    }}
                  />
                  <Radio
                    color={colors.accent}
                    label="Cancel"
                    active={true}
                    onClick={() => {
                      setShowUndoCustom(false);
                    }}
                  />
                </div>
              ) : isComplex ? (
                <>
                  <Radio
                    labelStyle={styles.leftRadio}
                    color={colors.disabled}
                    label="Yes"
                    active={false}
                    onClick={() => {
                      setDesiredSelfInstall(true);
                      setShowUndoCustom(true);
                    }}
                  />
                  <Radio
                    color={colors.disabled}
                    label="No, I want help."
                    active={false}
                    onClick={() => {
                      setDesiredSelfInstall(false);
                      setShowUndoCustom(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <Radio
                    labelStyle={styles.leftRadio}
                    color={colors.textOnAccent}
                    label="Yes"
                    active={selfInstall}
                    onClick={() => {
                      setConfig({ variables: { urlSlug, selfInstall: true } });
                      setSelfInstall(true);
                    }}
                  />
                  <Radio
                    color={colors.textOnAccent}
                    label="No, I want help."
                    active={!selfInstall}
                    onClick={() => {
                      setConfig({ variables: { urlSlug, selfInstall: false } });
                      setSelfInstall(false);
                    }}
                  />
                </>
              )}
            </div>
          </QueryParams>
          <Products
            selfInstall={isComplex ? undefined : selfInstall}
            {...{ screenData, urlSlug }}
          />
          <Actions
            screenData={screenData}
            urlSlug={urlSlug}
            selfInstall={isComplex ? undefined : selfInstall}
          />
          <Resources screenData={screenData} urlSlug={urlSlug} />
        </>
      )}
      <Footer />
    </>
  );
};

export default Details;

const styles = StyleSheet.create({
  topWrapper: {
    paddingTop: 40,
    paddingBottom: 20,
    "@media (max-width: 768px)": {
      paddingTop: 40,
      paddingBottom: 20,
    },
  },
  inner: {
    maxWidth: 768,
  },
  detailWrapper: {
    backgroundColor: colors.detailsBg,
  },
  heading: {
    fontSize: "170%",
    color: colors.accent,
    marginBottom: 10,
  },
  topText: {
    lineHeight: "1.2em",
  },
  topField: {
    textAlign: "center",
  },
  topFieldText: {
    fontSize: "85%",
    margin: "20px 0",
    color: colors.darkTextOnAccent,
  },
  undoCustom: {
    padding: "40px 20px",
    backgroundColor: colors.detailsBg,
    color: colors.text,
    maxWidth: 500,
    margin: "0 auto",
    borderRadius: 5,
    boxShadow: "rgba(0,0,0,0.15) 0 3px 5px",
  },
  undoCustomText: {
    lineHeight: "1.4em",
    marginBottom: 10,
  },
  leftRadio: {
    paddingRight: 40,
  },
});
