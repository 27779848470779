import { css, StyleSheet } from "aphrodite/no-important";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";

import logo from "static/yes-we-are-open.svg";
import menuIcon from "static/menu-icon.svg";
import verdicityLogo from "static/verdicity-logo.png";

const navItems = [
  { text: "Open", uri: "https://verdicity.com/open", internal: false },
  { text: "Buy", uri: "https://verdicity.com/buy", internal: false },
  { text: "FAQs", uri: "https://verdicity.com/faqs", internal: false },
  {
    text: "Feed",
    uri: "/resources-to-help-reopen-u-s-buisnesses/",
    internal: true,
  },
  { text: "Privacy", uri: "https://verdicity.com/privacy/", internal: false },
  { text: "Contact Us", uri: "/contact-us/", internal: true },
];

export const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <header>
      <PageBand>
        <nav className={css(headerStyles.nav)}>
          <div className={css(headerStyles.logoContainer)}>
            <Link to="/" className={css(headerStyles.logoLink)}>
              <img
                src={logo}
                className={css(headerStyles.logo)}
                alt="Yes We Are Open Logo"
              />
            </Link>
            <div className={css(headerStyles.logoBy)}>by</div>
            <a href="https://verdicity.com">
              <img
                src={verdicityLogo}
                alt="Verdicity"
                className={css(headerStyles.verdicityLogo)}
              />
            </a>
          </div>
          {navItems.map((item, index) => {
            if (item.internal) {
              return (
                <NavLink
                  key={index}
                  to={item.uri}
                  className={css(headerStyles.navLink)}
                >
                  {item.text}
                </NavLink>
              );
            } else {
              return (
                <a
                  target="_blank"
                  href={item.uri}
                  className={css(headerStyles.navLink)}
                  rel="noopener noreferrer"
                >
                  {item.text}
                </a>
              );
            }
          })}
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className={css(headerStyles.hamburger)}
          >
            <img
              src={menuIcon}
              alt="Show/Hide Menu"
              className={css(headerStyles.hamburgerImg)}
            />
          </button>
        </nav>
        <nav
          className={css(
            headerStyles.mobileNav,
            showMobileMenu ? headerStyles.mobileNavOpen : null
          )}
        >
          {navItems.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.uri}
                className={css(headerStyles.mobileNavLink)}
              >
                {item.text}
              </NavLink>
            );
          })}
        </nav>
      </PageBand>
    </header>
  );
};

const headerStyles = StyleSheet.create({
  nav: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logoContainer: {
    marginRight: "auto",
    display: "flex",
    alignItems: "baseline",
  },
  logoLink: {
    marginRight: "auto",
  },
  logo: {
    height: 74,
    width: "auto",
    "@media (max-width: 768px)": {
      height: 50,
    },
  },
  logoBy: {
    alignSelf: "center",
    color: colors.accent,
    marginLeft: 30,
    marginRight: 10,
    marginTop: 48,
    fontSize: 16,
    "@media (max-width: 768px)": {
      marginLeft: 25,
      marginRight: 5,
      marginTop: 30,
      fontSize: 12,
    },
  },
  verdicityLogo: {
    height: 35,
    width: "auto",
    "@media (max-width: 768px)": {
      height: 25,
    },
  },
  navLink: {
    color: colors.accent,
    textDecoration: "none",
    marginLeft: 36,
    fontSize: "110%",
    ":hover": {
      textDecoration: "underline",
    },
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  hamburger: {
    border: 0,
    background: "none",
    cursor: "pointer",
    "@media (min-width: 768px)": {
      display: "none",
    },
  },
  hamburgerImg: {
    width: 30,
    height: 20,
  },
  mobileNav: {
    display: "none",
    position: "fixed",
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: colors.pageBg,
    zIndex: 5,
    paddingTop: 40,
    paddingRight: 20,
    paddingBottom: 40,
    paddingLeft: 20,
  },
  mobileNavOpen: {
    display: "block",
  },
  mobileNavLink: {
    lineHeight: "2em",
    display: "block",
    color: colors.accent,
    fontSize: "110%",
  },
});

export const Footer = () => {
  return (
    <footer>
      <PageBand
        hasDoorWatermark={true}
        wrapperStyle={footerStyles.wrapper}
        watermarkStyle={footerStyles.watermark}
      >
        <nav className={css(footerStyles.nav)}>
          {navItems.map((item, index) => {
            if (item.internal) {
              return (
                <NavLink
                  key={index}
                  to={item.uri}
                  className={css(footerStyles.navLink)}
                >
                  {item.text}
                </NavLink>
              );
            } else {
              return (
                <a
                  target="_blank"
                  href={item.uri}
                  className={css(footerStyles.navLink)}
                  rel="noopener noreferrer"
                >
                  {item.text}
                </a>
              );
            }
          })}
          <div className={css(footerStyles.logoContainer)}>
            <Link to="/" className={css(headerStyles.logoLink)}>
              <img
                src={logo}
                className={css(headerStyles.logo)}
                alt="Yes We Are Open Logo"
              />
            </Link>
            <div className={css(headerStyles.logoBy)}>by</div>
            <a href="https://verdicity.com">
              <img
                src={verdicityLogo}
                alt="Verdicity"
                className={css(headerStyles.verdicityLogo)}
              />
            </a>
          </div>
        </nav>
      </PageBand>
    </footer>
  );
};

const footerStyles = StyleSheet.create({
  wrapper: {
    borderTop: `1px solid ${colors.accent}`,
  },
  nav: {
    padding: "50px 20px 60px",
    width: "250px",
    display: "flex",
    flexWrap: "wrap",
  },
  logoContainer: {
    marginRight: "auto",
    display: "flex",
    alignItems: "baseline",
    marginTop: 80,
  },
  navLink: {
    color: colors.text,
    textDecoration: "none",
    display: "block",
    fontSize: "110%",
    marginBottom: 20,
    width: "50%",
    ":hover": {
      textDecoration: "underline",
    },
  },
  watermark: {
    top: 50,
  },
});
