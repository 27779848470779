import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from 'shared/styles/colors';
import { Style } from 'shared/types';

import openYourDoors from 'static/open-your-doors.svg';

interface Props {
  children?: React.ReactNode,
  hasDoorWatermark?: boolean,
  wrapperStyle?: Style | Style[],
  innerStyle?: Style | Style[],
  watermarkStyle?: Style | Style[],
}

export const PageBand = ({ children, hasDoorWatermark, wrapperStyle, innerStyle, watermarkStyle }: Props) => {
  return (
    <div className={css(styles.wrapper, wrapperStyle)}>
      <div className={css(styles.inner, hasDoorWatermark ? styles.innerWatermark : null, innerStyle)}>
        {children}
        {hasDoorWatermark && (
          <img src={openYourDoors} className={css(styles.doorWatermark, watermarkStyle)} alt="Open door watermark" />
        )}
      </div>
    </div>
  )
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.pageBg,
    color: colors.text,
    fontFamily: 'Rubik, sans-serif',
    paddingTop: 40,
    paddingRight: 40,
    paddingBottom: 20,
    paddingLeft: 40,
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      paddingTop: 0,
      paddingRight: 20,
      paddingBottom: 0,
      paddingLeft: 20,
    },
  },
  inner: {
    maxWidth: 1225,
    width: '100%',
    margin: '0 auto',
  },
  innerWatermark: {
    position: 'relative',
  },
  doorWatermark: {
    position: 'absolute',
    right: 0,
    top: 110,
    width: 300,
    '@media (max-width: 786px)': {
      display: 'none',
    },
  },
});

