export function productQuantity(product, queryLead) {
  return Math.ceil(
    product.quantityMultiplier *
      (product.quantityByBuildingSize ? queryLead.buildingSize : 1)
  );
}

export function productUnitPrice(product, selfInstall?) {
  return (
    product.unitPriceCents +
    (product.installationPriceCents
      ? selfInstall
        ? product.provisionPriceCents
        : product.installationPriceCents + product.provisionPriceCents
      : 0)
  );
}

export function productCostEstimate(product, queryLead, selfInstall?) {
  return Math.round(
    Math.round(
      productUnitPrice(product, selfInstall) *
        productQuantity(product, queryLead)
    ) / 100
  );
}

export function totalEstimate(data, selfInstall?) {
  return data.leadProductOfferings.reduce(
    (amount, product) =>
      amount +
      productCostEstimate(
        product,
        data.leadDetails,
        selfInstall !== undefined
          ? selfInstall
          : prodConfigValue("selfInstall", product.id, data.queryProducts, true)
      ),
    0
  );
}

export function prodConfigValue(key, prodId, queryProducts, defaultVal?) {
  let queryLevel, productLevel;
  for (let prod of queryProducts) {
    if (prod.productId === null) queryLevel = prod[key];
    else if (prod.productId === prodId) productLevel = prod[key];
  }
  if (productLevel !== undefined) return productLevel;
  if (queryLevel !== undefined) return queryLevel;
  return defaultVal;
}
