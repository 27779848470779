import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import { Style } from 'shared/types';

interface Props {
  children?: React.ReactNode,
  style?: Style | Style[],
}

const Subtext = ({ children, style }: Props) => {
  return (<p className={css(styles.subtext, style)}>{children}</p>);
};

const styles = StyleSheet.create({
  subtext: {
    fontSize: '160%',
    marginBottom: 40,
    lineHeight: '1.3em',
    '@media (max-width: 768px)': {
      fontSize: '100%',
    }
  },
});

export default Subtext;
