import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from 'shared/styles/colors';

interface Props {
  text: String,
  emText?: String,
  emNewLine?: boolean,
  isOnAccentBg?: boolean,
  isTopLevel?: boolean,
}

const SectionHeading = ({ text, emText, emNewLine, isOnAccentBg, isTopLevel }: Props) => {
  const insideBits = (<>
    {text}
    {emNewLine && (<br />)}
    {emText && (<span className={css(styles.headingEm, isOnAccentBg ? styles.headingEmOnAccent : null)}>{emText}</span>)}
  </>);
  if(isTopLevel) {
    return (<h1 className={css(styles.heading)}>{insideBits}</h1>);
  }
  return (<h2 className={css(styles.heading)}>{insideBits}</h2>);
};

const styles = StyleSheet.create({
  heading: {
    fontSize: '550%',
    fontWeight: 400,
    marginBottom: 40,
    '@media (max-width: 768px)': {
      fontSize: '300%',
    }
  },
  headingEm: {
    fontWeight: 700,
    color: colors.accent,
  },
  headingEmOnAccent: {
    color: colors.textOnAccent,
  },
});

export default SectionHeading;
