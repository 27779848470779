import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

// Exported so that the headers can be customized for each request
export const reqHeaders = {};

const client = new ApolloClient({
  link: createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_API_URL}/api/graphql`,
    credentials: "same-origin",
    headers: reqHeaders,
  }),
  cache: new InMemoryCache(),
});

export default client;
