import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";

import * as colors from 'shared/styles/colors';

import { PageBand } from 'shared/components/PageBand';

const ErrorScreen = () => {
  return (
    <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
      <h1 className={css(styles.heading)}>An Error has Occurred</h1>
      <p className={css(styles.text)}>Please check the URL or refresh to try again.</p>
    </PageBand>
  );
};

export default ErrorScreen;

const styles = StyleSheet.create({
  wrapper: {
    width: '100vw',
    height: '100vh',
  },
  inner: {
    maxWidth: 400,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: '170%',
    color: colors.accent,
    marginBottom: 30,
  },
  text: {
    fontSize: '90%',
  },
});

