import "./reset.css";
import { Route, Switch } from "react-router";
import * as Sentry from "@sentry/browser";
import React, { Suspense } from "react";
import Home from "modules/home/screens/Home/Home";
import Feed from "modules/home/screens/Feed/Feed";
import PrivacyPolicy from "modules/home/screens/Privacy/Privacy";
import Contact from "modules/home/screens/Contact/Contact";
import Details from "modules/details/screens/Details/Details";
import Resource from "modules/details/screens/Resource/Resource";
import AllResources from "modules/details/screens/AllResources/AllResources";
import Product from "modules/details/screens/Product/Product";

import Loading from 'shared/components/Loading';
import { ScrollToTop } from "shared/utils";

const AdminScreen = React.lazy(() => import("modules/admin/screens/Admin/Admin"));

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

if(process.env.REACT_APP_MATOMO_DOMAIN) {
  window['_paq'] = [
    'trackPageView',
    'enableLinkTracking',
    'setTrackerUrl', `https://${process.env.REACT_APP_MATOMO_DOMAIN}/matomo.php`,
    'setSiteId', process.env.REACT_APP_MATOMO_SITE_ID || '1',
  ];

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `//cdn.matomo.cloud/${process.env.REACT_APP_MATOMO_DOMAIN}/matomo.js`;
  document.body.appendChild(script);
}


const App = () => {
  return (<>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/pak/:urlSlug/resource/:resourceId" component={Resource} />
        <Route path="/pak/:urlSlug/resource" component={AllResources} />
        <Route path="/pak/:urlSlug/product/:productId" component={Product} />
        <Route path="/pak/:urlSlug" component={Details} />
        <Route path="/resources-to-help-reopen-u-s-buisnesses" exact component={Feed} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/contact-us" exact component={Contact} />
        <Route path="/admin"><AdminScreen /></Route>
      </Switch>
    </Suspense>
    <ScrollToTop />
  </>);
};

export default App;
