import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite/no-important";
import React, { useState } from "react";

import * as colors from "shared/styles/colors";

import { PageBand } from "shared/components/PageBand";
import { SmallButton } from "shared/components/SmallButton";
import ProductTypeExpander from "shared/components/ProductTypeExpander";

import {
  productCostEstimate,
  prodConfigValue,
} from "modules/details/estimateHelpers";

import rightArrow from "static/products/rightArrow.svg";

interface Props {
  screenData: any;
  urlSlug: String;
  selfInstall?: Boolean;
}

const Products = ({ selfInstall, screenData, urlSlug }: Props) => {
  const [allExpanded, setAllExpanded] = useState(false);

  function expandAll(event) {
    event.target.parentNode
      .querySelectorAll("details")
      .forEach((detailsEl) =>
        detailsEl[allExpanded ? "removeAttribute" : "setAttribute"]("open", "")
      );
    setAllExpanded(!allExpanded);
  }

  return (
    <PageBand wrapperStyle={styles.wrapper} innerStyle={styles.inner}>
      <h2 className={css(styles.heading)}>Recommended Products and Services</h2>
      <SmallButton style={styles.expandAll} onClick={expandAll}>
        {allExpanded ? "Collapse All" : "Expand All"}
      </SmallButton>
      <ul className={css(styles.productTypeList)}>
        {productsSortByType(screenData.data.leadProductOfferings).map(
          (productType, index) => {
            return (
              <li key={index} className={css(styles.productTypeLi)}>
                <ProductTypeExpander
                  title={productType.name}
                  iconName={productType.iconName}
                  rightText={
                    "$" +
                    productType.products
                      .reduce(
                        (total, product) =>
                          total +
                          productCostEstimate(
                            product,
                            screenData.data.leadDetails,
                            selfInstall !== undefined
                              ? selfInstall
                              : prodConfigValue(
                                  "selfInstall",
                                  product.id,
                                  screenData.data.queryProducts,
                                  true
                                )
                          ),
                        0
                      )
                      .toLocaleString()
                  }
                >
                  <ul className={css(styles.productList)}>
                    {productType.products.map((product, index) => {
                      return (
                        <li key={index} className={css(styles.productLi)}>
                          <Link
                            to={`/pak/${urlSlug}/product/${product.id}`}
                            className={css(
                              styles.productA,
                              index === productType.products.length - 1
                                ? styles.lastProduct
                                : null
                            )}
                          >
                            <div className={css(styles.productBox)}>
                              <div className={css(styles.productLeft)}>
                                <h4 className={css(styles.productName)}>
                                  {product.name}
                                </h4>
                                <p className={css(styles.companyName)}>
                                  {product.companyName}
                                </p>
                              </div>
                              <div className={css(styles.productRight)}>
                                <p className={css(styles.estimateValue)}>
                                  $
                                  {productCostEstimate(
                                    product,
                                    screenData.data.leadDetails,
                                    selfInstall !== undefined
                                      ? selfInstall
                                      : prodConfigValue(
                                          "selfInstall",
                                          product.id,
                                          screenData.data.queryProducts,
                                          true
                                        )
                                  ).toLocaleString()}{" "}
                                  Total{product.priceIsCustom && "*"}
                                </p>
                              </div>
                            </div>
                            <p className={css(styles.shortDesc)}>
                              {product.shortDesc}
                            </p>
                            <img
                              src={rightArrow}
                              alt="Product Details"
                              className={css(styles.rightArrow)}
                            />
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </ProductTypeExpander>
              </li>
            );
          }
        )}
      </ul>
    </PageBand>
  );
};

export default Products;

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.detailsBg,
    boxShadow: "0 3px 5px rgba(0,0,0,0.15)",
    zIndex: 1,
    paddingTop: 40,
    paddingBottom: 40,
    "@media (max-width: 768px)": {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  inner: {
    maxWidth: 768,
  },
  heading: {
    fontWeight: 400,
    lineHeight: "1.2em",
  },
  expandAll: {
    float: "right",
    marginTop: 10,
    marginBottom: 16,
  },
  productTypeList: {
    clear: "both",
  },
  productTypeLi: {
    boxShadow: "0 1px 4px rgba(0,0,0,0.15)",
    borderRadius: 7,
    marginBottom: 8,
  },
  productList: {},
  productLi: {},
  productA: {
    position: "relative",
    borderColor: colors.accent,
    borderWidth: "1px 1px 0 1px",
    borderStyle: "solid",
    padding: "15px 30px 15px 10px",
    color: colors.text,
    display: "block",
    ":hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  productBox: {
    display: "flex",
  },
  productLeft: {
    flex: 1,
  },
  productRight: {
    textAlign: "right",
  },
  lastProduct: {
    borderBottomWidth: 1,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  productName: {
    fontSize: "110%",
    fontWeight: 700,
    marginBottom: 5,
  },
  companyName: {
    fontSize: "90%",
    lineHeight: "1.2em",
    fontWeight: 700,
    marginBottom: 7,
  },
  shortDesc: {
    fontSize: "90%",
    lineHeight: "1.3em",
    display: "block",
    marginTop: 20,
  },
  estimateValue: {
    marginBottom: 5,
    fontSize: "110%",
    fontWeight: 700,
  },
  rightArrow: {
    position: "absolute",
    top: "50%",
    height: 25,
    marginTop: -12,
    right: 10,
  },
});

function productsSortByType(products) {
  const types: any[] = [];
  for (let product of products) {
    let productType;
    for (let type of types) {
      if (type.id === product.productType.id) {
        productType = type;
        break;
      }
    }
    if (!productType) {
      productType = product.productType;
      productType.products = [];
      types.push(productType);
    }
    productType.products.push(product);
  }
  types.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
  return types;
}
